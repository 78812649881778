export const Data_c1 = [
  {
    title: "Apartament 4",
    etaj: "1",
    src: "/images/C1/etaj_1/AP 4-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "69,37 mp",
    id: 1,
    href: "https://kuula.co/share/collection/7YCvY?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.38 mp",
  },
  {
    title: "Apartament 5",
    etaj: "1",
    src: "/images/C1/etaj_1/AP 5-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "66 mp",
    suprafataUtila: "55.24 mp",
    id: 2,
    href: "https://kuula.co/share/collection/7YCcH?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "0 mp",
  },
  {
    title: "Apartament 6",
    etaj: "1",
    src: "/images/C1/etaj_1/AP 6-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "74 mp",
    suprafataUtila: "59,02 mp",
    id: 3,
    href: "https://kuula.co/share/collection/7Yspm?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.84 mp",
  },
  {
    title: "Apartament 7",
    etaj: "1",
    src: "/images/C1/etaj_1/AP 7-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "75 mp",
    suprafataUtila: "59,60 mp",
    id: 4,
    href: "https://kuula.co/share/collection/7Y24Q?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.13 mp",
  },
  {
    title: "Apartament 8",
    etaj: "1",
    src: "/images/C1/etaj_1/AP 8-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "88 mp",
    suprafataUtila: "66,65 mp",
    id: 5,
    href: "https://kuula.co/share/collection/7YyKS?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "6.38 mp",
  },
  {
    title: "Apartament 9",
    etaj: "1",
    src: "/images/C1/etaj_1/AP 9-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "100 mp",
    suprafataUtila: "79,84 mp",
    id: 6,
    href: "https://kuula.co/share/collection/7kTHC?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.04 mp",
  },
  {
    title: "Apartament 10",
    etaj: "2",
    src: "/images/C1/etaj_2/AP 10-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "69,37 mp",
    id: 7,
    href: "https://kuula.co/share/collection/7YCvY?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.38 mp",
  },
  {
    title: "Apartament 11",
    etaj: "2",
    src: "/images/C1/etaj_2/AP 11-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "66 mp",
    suprafataUtila: "55,24 mp",
    id: 8,
    href: "https://kuula.co/share/collection/7YCcH?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "0 mp",
  },
  {
    title: "Apartament 12",
    etaj: "2",
    src: "/images/C1/etaj_2/AP 12-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "74 mp",
    suprafataUtila: "59,02 mp",
    id: 9,
    href: "https://kuula.co/share/collection/7Yspm?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.84 mp",
  },
  {
    title: "Apartament 13",
    etaj: "2",
    src: "/images/C1/etaj_2/AP 13-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "75 mp",
    suprafataUtila: "59,60 mp",
    id: 10,
    href: "https://kuula.co/share/collection/7Y24Q?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.13 mp",
  },
  {
    title: "Apartament 14",
    etaj: "2",
    src: "/images/C1/etaj_2/AP 14-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "88 mp",
    suprafataUtila: "66,65 mp",
    id: 11,
    href: "https://kuula.co/share/collection/7YyKS?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "6.38 mp",
  },
  {
    title: "Apartament 15",
    etaj: "2",
    src: "/images/C1/etaj_2/AP 15-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "100 mp",
    suprafataUtila: "79,84 mp",
    id: 12,
    href: "https://kuula.co/share/collection/7kTHC?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.04 mp",
  },
  {
    title: "Apartament 16",
    etaj: "3",
    src: "/images/C1/etaj_3/AP 16-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "69,37 mp",
    id: 13,
    href: "https://kuula.co/share/collection/7YCvY?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.38 mp",
  },
  {
    title: "Apartament 17",
    etaj: "3",
    src: "/images/C1/etaj_3/AP 17-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "66 mp",
    suprafataUtila: "55.24 mp",
    id: 14,
    href: "https://kuula.co/share/collection/7YCcH?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "0 mp",
  },
  {
    title: "Apartament 18",
    etaj: "3",
    src: "/images/C1/etaj_3/AP 18-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "74 mp",
    suprafataUtila: "59,02 mp",
    id: 15,
    href: "https://kuula.co/share/collection/7Yspm?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.84 mp",
  },
  {
    title: "Apartament 19",
    etaj: "3",
    src: "/images/C1/etaj_3/AP 19-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "75 mp",
    suprafataUtila: "59,60 mp",
    id: 16,
    href: "https://kuula.co/share/collection/7Y24Q?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.13 mp",
  },
  {
    title: "Apartament 20",
    etaj: "3",
    src: "/images/C1/etaj_3/AP 20-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "88 mp",
    suprafataUtila: "66,65 mp",
    id: 17,
    href: "https://kuula.co/share/collection/7YyKS?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "6.38 mp",
  },
  {
    title: "Apartament 21",
    etaj: "3",
    src: "/images/C1/etaj_3/AP 21-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "100 mp",
    suprafataUtila: "79,84 mp",
    id: 18,
    href: "https://kuula.co/share/collection/7kTHC?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.04 mp",
  },
  {
    title: "Apartament 22",
    etaj: "4",
    src: "/images/C1/etaj_4/AP 22-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "69,37 mp",
    id: 19,
    href: "https://kuula.co/share/collection/7YCvY?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.38 mp",
  },
  {
    title: "Apartament 23",
    etaj: "4",
    src: "/images/C1/etaj_4/AP 23-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "66 mp",
    suprafataUtila: "55.24 mp",
    id: 20,
    href: "https://kuula.co/share/collection/7YCcH?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "0 mp",
  },
  {
    title: "Apartament 24",
    etaj: "4",
    src: "/images/C1/etaj_4/AP 24-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "74 mp",
    suprafataUtila: "59,02 mp",
    id: 21,
    href: "https://kuula.co/share/collection/7Yspm?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.84 mp",
  },
  {
    title: "Apartament 25",
    etaj: "4",
    src: "/images/C1/etaj_4/AP 25-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "75 mp",
    suprafataUtila: "59,60 mp",
    id: 22,
    href: "https://kuula.co/share/collection/7Y24Q?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.13 mp",
  },
  {
    title: "Apartament 26",
    etaj: "4",
    src: "/images/C1/etaj_4/AP 26-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "88 mp",
    suprafataUtila: "66,65 mp",
    id: 23,
    href: "https://kuula.co/share/collection/7YyKS?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "6.38 mp",
  },
  {
    title: "Apartament 27",
    etaj: "4",
    src: "/images/C1/etaj_4/AP 27-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "100 mp",
    suprafataUtila: "79,84 mp",
    id: 24,
    href: "https://kuula.co/share/collection/7kTHC?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.04 mp",
  },
  {
    title: "Apartament 28",
    etaj: "5",
    src: "/images/C1/etaj_5/AP 28-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "69,37 mp",
    id: 25,
    href: "https://kuula.co/share/collection/7YCvY?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.38 mp",
  },
  {
    title: "Apartament 29",
    etaj: "5",
    src: "/images/C1/etaj_5/AP 29-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "66 mp",
    suprafataUtila: "55.24 mp",
    id: 26,
    href: "https://kuula.co/share/collection/7YCcH?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "0 mp",
  },
  {
    title: "Apartament 30",
    etaj: "5",
    src: "/images/C1/etaj_5/AP 30-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "74 mp",
    suprafataUtila: "59,02 mp",
    id: 27,
    href: "https://kuula.co/share/collection/7Yspm?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.84 mp",
  },
  {
    title: "Apartament 31",
    etaj: "5",
    src: "/images/C1/etaj_5/AP 31-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "75 mp",
    suprafataUtila: "59,60 mp",
    id: 28,
    href: "https://kuula.co/share/collection/7Y24Q?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.13 mp",
  },
  {
    title: "Apartament 32",
    etaj: "5",
    src: "/images/C1/etaj_5/AP 32-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "88 mp",
    suprafataUtila: "66,65 mp",
    id: 29,
    href: "https://kuula.co/share/collection/7YyKS?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "6.38 mp",
  },
  {
    title: "Apartament 33",
    etaj: "5",
    src: "/images/C1/etaj_5/AP 33-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "100 mp",
    suprafataUtila: "79,84 mp",
    id: 30,
    href: "https://kuula.co/share/collection/7kTHC?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.04 mp",
  },
  {
    title: "Apartament 34",
    etaj: "6",
    src: "/images/C1/etaj_6/AP 34-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "69,37 mp",
    id: 31,
    href: "https://kuula.co/share/collection/7YCvY?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.38 mp",
  },
  {
    title: "Apartament 35",
    etaj: "6",
    src: "/images/C1/etaj_6/AP 35-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "66 mp",
    suprafataUtila: "55.24 mp",
    id: 32,
    href: "https://kuula.co/share/collection/7YCcH?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "0 mp",
  },
  {
    title: "Apartament 36",
    etaj: "6",
    src: "/images/C1/etaj_6/AP 36-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "74 mp",
    suprafataUtila: "59,02 mp",
    id: 33,
    href: "https://kuula.co/share/collection/7Yspm?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.84 mp",
  },
  {
    title: "Apartament 37",
    etaj: "6",
    src: "/images/C1/etaj_6/AP 37-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "75 mp",
    suprafataUtila: "59,60 mp",
    id: 34,
    href: "https://kuula.co/share/collection/7Y24Q?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.13 mp",
  },
  {
    title: "Apartament 38",
    etaj: "6",
    src: "/images/C1/etaj_6/AP 38-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "88 mp",
    suprafataUtila: "66,65 mp",
    id: 35,
    href: "https://kuula.co/share/collection/7YyKS?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "6.38 mp",
  },
  {
    title: "Apartament 39",
    etaj: "6",
    src: "/images/C1/etaj_6/AP 39-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "100 mp",
    suprafataUtila: "79,84 mp",
    id: 36,
    href: "https://kuula.co/share/collection/7kTHC?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.04 mp",
  },
  {
    title: "Apartament 40",
    etaj: "7",
    src: "/images/C1/etaj_7/AP 40-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "69,37 mp",
    id: 37,
    href: "https://kuula.co/share/collection/7YCvY?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.38 mp",
  },
  {
    title: "Apartament 41",
    etaj: "7",
    src: "/images/C1/etaj_7/AP 41-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "66 mp",
    suprafataUtila: "55.24 mp",
    id: 38,
    href: "https://kuula.co/share/collection/7YCcH?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "0 mp",
  },
  {
    title: "Apartament 42",
    etaj: "7",
    src: "/images/C1/etaj_7/AP 42-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "74 mp",
    suprafataUtila: "59,02 mp",
    id: 39,
    href: "https://kuula.co/share/collection/7Yspm?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.84 mp",
  },
  {
    title: "Apartament 43",
    etaj: "7",
    src: "/images/C1/etaj_7/AP 43-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 1",
    suprafataConstruita: "75 mp",
    suprafataUtila: "59,60 mp",
    id: 40,
    href: "https://kuula.co/share/collection/7Y24Q?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.13 mp",
  },
  {
    title: "Apartament 44",
    etaj: "7",
    src: "/images/C1/etaj_7/AP 44-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "88 mp",
    suprafataUtila: "66,65 mp",
    id: 41,
    href: "https://kuula.co/share/collection/7YyKS?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "6.38 mp",
  },
  {
    title: "Apartament 45",
    etaj: "7",
    src: "/images/C1/etaj_7/AP 45-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "100 mp",
    suprafataUtila: "79,84 mp",
    id: 42,
    href: "https://kuula.co/share/collection/7kTHC?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.04 mp",
  },
  {
    title: "Apartament 46",
    etaj: "8",
    src: "/images/C1/etaj_8/AP 46-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "117,00 mp",
    suprafataUtila: "75,79 mp",
    balcon: "26.00 mp",
    id: 43,
  },
  {
    title: "Apartament 47",
    etaj: "8",
    src: "/images/C1/etaj_8/AP 47-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "123,00 mp",
    suprafataUtila: "81,62 mp",
    id: 44,
    href: "https://kuula.co/share/collection/7kZFT?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "25.00 mp",
  },
  {
    title: "Apartament 48",
    etaj: "8",
    src: "/images/C1/etaj_8/AP 48-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "126,00 mp",
    suprafataUtila: "82,66 mp",
    id: 45,
    href: "https://kuula.co/share/collection/7kd4q?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "27.00 mp",
  },
  {
    title: "Apartament 49",
    etaj: "8",
    src: "/images/C1/etaj_8/AP 49-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 1",
    suprafataConstruita: "143,00 mp",
    suprafataUtila: "89,79 mp",
    id: 46,
    balcon: "33.00 mp",
  },

  {
    title: "Apartament 4",
    etaj: "1",
    src: "/images/C2/etaj_1/AP 04-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "69,37 mp",
    id: 47,
    href: "https://kuula.co/share/collection/7YCvY?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.38 mp",
  },
  {
    title: "Apartament 5",
    etaj: "1",
    src: "/images/C2/etaj_1/AP 5-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "66,00 mp",
    suprafataUtila: "55,24 mp",
    id: 48,
    href: "https://kuula.co/share/collection/7YCcH?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "0 mp",
  },
  {
    title: "Apartament 6",
    etaj: "1",
    src: "/images/C2/etaj_1/AP 6-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "74,00 mp",
    suprafataUtila: "59,02 mp",
    id: 49,
    href: "https://kuula.co/share/collection/7Yspm?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.84 mp",
  },
  {
    title: "Apartament 7",
    etaj: "1",
    src: "/images/C2/etaj_1/AP 7-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "75,00 mp",
    suprafataUtila: "59,60 mp",
    id: 50,
    href: "https://kuula.co/share/collection/7Y24Q?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.13 mp",
  },
  {
    title: "Apartament 8",
    etaj: "1",
    src: "/images/C2/etaj_1/AP 8-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "66,65 mp",
    id: 51,
    href: "https://kuula.co/share/collection/7YyKS?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "6.38 mp",
  },
  {
    title: "Apartament 9",
    etaj: "1",
    src: "/images/C2/etaj_1/AP 9-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "100,00 mp",
    suprafataUtila: "79,84 mp",
    id: 52,
    href: "https://kuula.co/share/collection/7kTHC?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.04 mp",
  },
  {
    title: "Apartament 10",
    etaj: "2",
    src: "/images/C2/etaj_2/AP 10-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "69,37 mp",
    id: 53,
    href: "https://kuula.co/share/collection/7YCvY?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.38 mp",
  },
  {
    title: "Apartament 11",
    etaj: "2",
    src: "/images/C2/etaj_2/AP 11-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "66,00 mp",
    suprafataUtila: "55,24 mp",
    id: 54,
    href: "https://kuula.co/share/collection/7YCcH?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "0 mp",
  },
  {
    title: "Apartament 12",
    src: "/images/C2/etaj_2/AP 12-min.jpg",
    etaj: "2",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "74,00 mp",
    suprafataUtila: "59,02 mp",
    id: 55,
    href: "https://kuula.co/share/collection/7Yspm?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.84 mp",
  },
  {
    title: "Apartament 13",
    etaj: "2",
    src: "/images/C2/etaj_2/AP 13-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "75,00 mp",
    suprafataUtila: "59,60 mp",
    id: 56,
    href: "https://kuula.co/share/collection/7Y24Q?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.13 mp",
  },
  {
    title: "Apartament 14",
    etaj: "2",
    src: "/images/C2/etaj_2/AP 14-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "66,65 mp",
    id: 57,
    href: "https://kuula.co/share/collection/7YyKS?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "6.38 mp",
  },
  {
    title: "Apartament 15",
    etaj: "2",
    src: "/images/C2/etaj_2/AP 15-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "100,00 mp",
    suprafataUtila: "79,84 mp",
    id: 58,
    href: "https://kuula.co/share/collection/7kTHC?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.04 mp",
  },
  {
    title: "Apartament 16",
    etaj: "3",
    src: "/images/C2/etaj_3/AP 16-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "69,37 mp",
    id: 59,
    href: "https://kuula.co/share/collection/7YCvY?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.38 mp",
  },
  {
    title: "Apartament 17",
    etaj: "3",
    src: "/images/C2/etaj_3/AP 17-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "66,00 mp",
    suprafataUtila: "55,24 mp",
    id: 60,
    href: "https://kuula.co/share/collection/7YCcH?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "0 mp",
  },
  {
    title: "Apartament 18",
    etaj: "3",
    src: "/images/C2/etaj_3/AP 18-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "74,00 mp",
    suprafataUtila: "59,02 mp",
    id: 61,
    href: "https://kuula.co/share/collection/7Yspm?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.84 mp",
  },
  {
    title: "Apartament 19",
    etaj: "3",
    src: "/images/C2/etaj_3/AP 19-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "75,00 mp",
    suprafataUtila: "59,60 mp",
    id: 62,
    href: "https://kuula.co/share/collection/7Y24Q?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.13 mp",
  },
  {
    title: "Apartament 20",
    etaj: "3",
    src: "/images/C2/etaj_3/AP 20-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "66,65 mp",
    id: 63,
    href: "https://kuula.co/share/collection/7YyKS?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "6.38 mp",
  },
  {
    title: "Apartament 21",
    etaj: "3",
    src: "/images/C2/etaj_3/AP 21-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "100,00 mp",
    suprafataUtila: "79,84 mp",
    id: 64,
    href: "https://kuula.co/share/collection/7kTHC?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.04 mp",
  },
  {
    title: "Apartament 22",
    etaj: "4",
    src: "/images/C2/etaj_4/AP 22-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "69,37 mp",
    id: 65,
    href: "https://kuula.co/share/collection/7YCvY?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.38 mp",
  },
  {
    title: "Apartament 23",
    etaj: "4",
    src: "/images/C2/etaj_4/AP 23-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "66,00 mp",
    suprafataUtila: "55,24 mp",
    id: 66,
    href: "https://kuula.co/share/collection/7YCcH?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "0 mp",
  },
  {
    title: "Apartament 24",
    etaj: "4",
    src: "/images/C2/etaj_4/AP 24-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "74,00 mp",
    suprafataUtila: "59,02 mp",
    id: 67,
    href: "https://kuula.co/share/collection/7Yspm?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.84 mp",
  },
  {
    title: "Apartament 25",
    etaj: "4",
    src: "/images/C2/etaj_4/AP 25-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "75,00 mp",
    suprafataUtila: "59,60 mp",
    id: 68,
    href: "https://kuula.co/share/collection/7Y24Q?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.13 mp",
  },
  {
    title: "Apartament 26",
    etaj: "4",
    src: "/images/C2/etaj_4/AP 26-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "66,65 mp",
    id: 69,
    href: "https://kuula.co/share/collection/7YyKS?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "6.38 mp",
  },
  {
    title: "Apartament 27",
    etaj: "4",
    src: "/images/C2/etaj_4/AP 27-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "100,00 mp",
    suprafataUtila: "79,84 mp",
    id: 70,
    href: "https://kuula.co/share/collection/7kTHC?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.04 mp",
  },
  {
    title: "Apartament 28",
    etaj: "5",
    src: "/images/C2/etaj_5/AP 28-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "69,37 mp",
    id: 71,
    href: "https://kuula.co/share/collection/7YCvY?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.38 mp",
  },
  {
    title: "Apartament 29",
    etaj: "5",
    src: "/images/C2/etaj_5/AP 29-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "66,00 mp",
    suprafataUtila: "55,24 mp",
    id: 72,
    href: "https://kuula.co/share/collection/7YCcH?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "0 mp",
  },
  {
    title: "Apartament 30",
    etaj: "5",
    src: "/images/C2/etaj_5/AP 30-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "74,00 mp",
    suprafataUtila: "59,02 mp",
    id: 73,
    href: "https://kuula.co/share/collection/7Yspm?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.84 mp",
  },
  {
    title: "Apartament 31",
    etaj: "5",
    src: "/images/C2/etaj_5/AP 31-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "75,00 mp",
    suprafataUtila: "59,60 mp",
    id: 74,
    href: "https://kuula.co/share/collection/7Y24Q?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.13 mp",
  },
  {
    title: "Apartament 32",
    etaj: "5",
    src: "/images/C2/etaj_5/AP 32-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "66,65 mp",
    id: 75,
    href: "https://kuula.co/share/collection/7YyKS?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "6.38 mp",
  },
  {
    title: "Apartament 33",
    etaj: "5",
    src: "/images/C2/etaj_5/AP 33-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "100,00 mp",
    suprafataUtila: "79,84 mp",
    id: 76,
    href: "https://kuula.co/share/collection/7kTHC?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.04 mp",
  },
  {
    title: "Apartament 34",
    etaj: "6",
    src: "/images/C2/etaj_6/AP 34-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "69,37 mp",
    id: 77,
    href: "https://kuula.co/share/collection/7YCvY?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.38 mp",
  },
  {
    title: "Apartament 35",
    etaj: "6",
    src: "/images/C2/etaj_6/AP 35-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "66,00 mp",
    suprafataUtila: "55,24 mp",
    id: 78,
    href: "https://kuula.co/share/collection/7YCcH?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "0 mp",
  },
  {
    title: "Apartament 36",
    etaj: "6",
    src: "/images/C2/etaj_6/AP 36-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "74,00 mp",
    suprafataUtila: "59,02 mp",
    id: 79,
    href: "https://kuula.co/share/collection/7Yspm?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.84 mp",
  },
  {
    title: "Apartament 37",
    etaj: "6",
    src: "/images/C2/etaj_6/AP 37-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "75,00 mp",
    suprafataUtila: "59,60 mp",
    id: 80,
    href: "https://kuula.co/share/collection/7Y24Q?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.13 mp",
  },
  {
    title: "Apartament 38",
    etaj: "6",
    src: "/images/C2/etaj_6/AP 38-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "66,65 mp",
    id: 81,
    href: "https://kuula.co/share/collection/7YyKS?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "6.38 mp",
  },
  {
    title: "Apartament 39",
    etaj: "6",
    src: "/images/C2/etaj_6/AP 39-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "100,00 mp",
    suprafataUtila: "79,84 mp",
    id: 82,
    href: "https://kuula.co/share/collection/7kTHC?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.04 mp",
  },
  {
    title: "Apartament 40",
    etaj: "7",
    src: "/images/C2/etaj_7/AP 40-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "69,37 mp",
    id: 83,
    href: "https://kuula.co/share/collection/7YCvY?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.38 mp",
  },
  {
    title: "Apartament 41",
    etaj: "7",
    src: "/images/C2/etaj_7/AP 41-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "66,00 mp",
    suprafataUtila: "55,24 mp",
    id: 84,
    href: "https://kuula.co/share/collection/7YCcH?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "0 mp",
  },
  {
    title: "Apartament 42",
    etaj: "7",
    src: "/images/C2/etaj_7/AP 42-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "74,00 mp",
    suprafataUtila: "59,02 mp",
    id: 85,
    href: "https://kuula.co/share/collection/7Yspm?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "3.84 mp",
  },
  {
    title: "Apartament 43",
    etaj: "7",
    src: "/images/C2/etaj_7/AP 43-min.jpg",
    liber: "true",
    camere: "2",
    scara: "C 2",
    suprafataConstruita: "75,00 mp",
    suprafataUtila: "59,60 mp",
    id: 86,
    href: "https://kuula.co/share/collection/7Y24Q?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.13 mp",
  },
  {
    title: "Apartament 44",
    etaj: "7",
    src: "/images/C2/etaj_7/AP 44-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "88,00 mp",
    suprafataUtila: "66,65 mp",
    id: 87,
    href: "https://kuula.co/share/collection/7YyKS?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "6.38 mp",
  },
  {
    title: "Apartament 45",
    etaj: "7",
    src: "/images/C2/etaj_7/AP 45-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "100,00 mp",
    suprafataUtila: "79,84 mp",
    id: 88,
    href: "https://kuula.co/share/collection/7kTHC?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "4.04 mp",
  },
  {
    title: "Apartament 46",
    etaj: "8",
    src: "/images/C2/etaj_8/AP 46-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "117,00 mp",
    suprafataUtila: "75,79 mp",
    id: 89,
    balcon: "26.00 mp",
  },
  {
    title: "Apartament 47",
    etaj: "8",
    src: "/images/C2/etaj_8/AP 47-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "123,00 mp",
    suprafataUtila: "81,62 mp",
    id: 90,
    href: "https://kuula.co/share/collection/7kZFT?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "25.00 mp",
  },
  {
    title: "Apartament 48",
    etaj: "8",
    src: "/images/C2/etaj_8/AP 48-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "126,00 mp",
    suprafataUtila: "82,66 mp",
    id: 91,
    href: "https://kuula.co/share/collection/7kd4q?logo=1&info=0&fs=1&vr=1&initload=0&thumbs=-1",
    balcon: "27.00 mp",
  },
  {
    title: "Apartament 49",
    etaj: "8",
    src: "/images/C2/etaj_8/AP 49-min.jpg",
    liber: "true",
    camere: "3",
    scara: "C 2",
    suprafataConstruita: "143,00 mp",
    suprafataUtila: "89,79 mp",
    id: 92,
    balcon: "33.00 mp",
  },
];
